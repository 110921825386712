import React from 'react'
import DesignSystem from '../../components/DesignSystem'

import Islands from './index'

const Kauai = () => {
  return (
    <Islands activeIsland="kauai">
      <DesignSystem.H1>Kauai</DesignSystem.H1>
      <DesignSystem.P>blurb</DesignSystem.P>
    </Islands>
  )
}

export default Kauai
